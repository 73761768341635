const defaultNegative = () => null;

/* *
 * util for rendering jsx if conditional is met
 * */
const renderIf = <PostitiveT = JSX.Element, NegativeT = JSX.Element | null>(
  conditional: boolean,
  affirmative: () => PostitiveT,
  negative?: () => NegativeT
) => (conditional ? affirmative() : (negative || defaultNegative)());

export default renderIf;
