import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { trackSearchTabClicked } from '../../../../util/trackingHelper';

import styles from './searchCategoryChips.module.less';
import { searchType } from '../utils/utils';

const categories = ['products', 'couples', 'vendors'];

const SearchCategoryChips = ({ category: selectedCategory, handleToggle, searchWord }) => {
  const handleMouseDown = (category) => {
    const trackingPayload = {
      query: searchWord,
      section: 'UNIVERSAL',
      search_type: searchType(category),
    };
    trackSearchTabClicked(trackingPayload);
    if (handleToggle) {
      handleToggle(category);
    }
  };

  return (
    <div className={styles.categoryChips}>
      {categories.map((category) => {
        return (
          <div
            className={cx(styles.searchChip, {
              [styles.selected]: selectedCategory === category,
            })}
            key={category}
            onMouseDown={() => handleMouseDown(category)}
          >
            {category}
          </div>
        );
      })}
    </div>
  );
};

SearchCategoryChips.propTypes = {
  category: PropTypes.oneOf(['products', 'vendors', 'couples']).isRequired,
  handleToggle: PropTypes.func,
  searchWord: PropTypes.string,
};

export default SearchCategoryChips;
