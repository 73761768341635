// bind all methods except whose that match this regex (react lifecycle methods)
const REGEX = /^(component.*|render)$/;

const getPropertyNames = <T extends React.Component>(instance: T): string[] => {
  let propertyNames: string[] = [];

  // starting with this instance and crawling our way up the prototype chain...
  // add all property names to an array until we hit the React.Component class
  for (
    let proto = instance;
    !Object.prototype.hasOwnProperty.call(proto, 'isReactComponent');
    proto = Object.getPrototypeOf(proto)
  ) {
    propertyNames = propertyNames.concat(Object.getOwnPropertyNames(proto));
  }

  return propertyNames;
};

/**
 * bind the `this` keyword in event handling class methods.
 * This is a side effects function, mutates instance parameter
 */
const bindMethods = <T extends React.Component>(instance: T) => {
  const copyOfInstance = instance;
  // look over all properties and methods of this object
  getPropertyNames(copyOfInstance)
    // filter to only methods (eliminate properties)
    .filter(
      (propertyName) =>
        typeof (copyOfInstance as Record<string, unknown>)[propertyName] === 'function'
    )
    // filter to only look at methods that don't match of regex
    .filter((methodName) => !methodName.match(REGEX))
    // bind these methods to the class instance
    .forEach((methodName) => {
      // @ts-expect-error meta programming is breaking TS
      copyOfInstance[methodName] = copyOfInstance[methodName].bind(instance);
    });
};

export default bindMethods;
