import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchIcon from 'components/navV3/iconsV3/Search';

import bindMethods from '../../../../util/bindMethods';

export default class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPlaceholder: true };
    this.searchInput = React.createRef();

    bindMethods(this);
  }

  static getDerivedStateFromProps(props) {
    const { searchWord } = props;
    if (!searchWord || searchWord === '') {
      return { isPlaceholder: true };
    }
    return { isPlaceholder: false };
  }

  componentDidMount() {
    const { isPlaceholder } = this.state;
    const { shouldFocus } = this.props;
    if (shouldFocus) {
      this.searchInput.current.focus();
      if (isPlaceholder) this.searchInput.current.setSelectionRange(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    const { isPlaceholder } = this.state;
    const { searchWord, onProductCategory, shouldFocus } = this.props;
    const wordWasCleared = prevProps.searchWord.length > 0 && searchWord.length === 0;
    const categoryTabToggledWithNoSearchWord =
      isPlaceholder && prevProps.onProductCategory !== onProductCategory;

    if (wordWasCleared || categoryTabToggledWithNoSearchWord) {
      this.searchInput.current.setSelectionRange(0, 0);
    }
    if (!prevProps.shouldFocus && shouldFocus) {
      this.searchInput.current.focus();
      if (isPlaceholder) this.searchInput.current.setSelectionRange(0, 0);
    }
  }

  onClick(e) {
    const { isPlaceholder } = this.state;
    if (isPlaceholder) e.target.setSelectionRange(0, 0);
  }

  onFocus(e) {
    const { isPlaceholder } = this.state;
    const { handleFocus } = this.props;
    if (isPlaceholder) e.target.setSelectionRange(0, 0);
    handleFocus();
  }

  renderClearIcon() {
    const { searchWord, clearSearch } = this.props;

    if (!searchWord) return null;

    return (
      <button
        type="button"
        className="search-input__clear-icon zola-ui-icon-close"
        onMouseDown={clearSearch}
      />
    );
  }

  render() {
    const { isPlaceholder } = this.state;
    const {
      handleKeyPress,
      handleSubmit,
      searchWord,
      placeholder,
      handleBlur,
      handleKeyDown,
      isMobileNavV3,
      isDesktopNavV3,
      isPostAuthNav3Enabled,
    } = this.props;
    const value = isPlaceholder ? placeholder : searchWord;

    return (
      <div
        className={classNames('search-input__container', {
          'search-input__container--active': searchWord,
          'search-input__container--mobileNavV3': isMobileNavV3,
          'search-input__container--desktopNavV3': isDesktopNavV3,
        })}
      >
        {isDesktopNavV3 || isMobileNavV3 ? (
          <SearchIcon
            className="search-input__mag-icon-v3"
            height={isDesktopNavV3 ? 24 : 20}
            width={isDesktopNavV3 ? 24 : 20}
            showTitle={false}
          />
        ) : (
          <i className="search-input__mag-icon zola-ui-icon-magnifying-glass" aria-hidden="true" />
        )}
        <input
          id="zola-search"
          className={classNames({
            'search-input__input': true,
            'override-placeholder': isPlaceholder,
            'search-input__input--postAuthNav3': isPostAuthNav3Enabled,
          })}
          aria-label={placeholder}
          value={value}
          onChange={handleKeyPress}
          onKeyPress={handleSubmit}
          onBlur={handleBlur}
          ref={this.searchInput}
          onKeyDown={handleKeyDown}
          onClick={this.onClick}
          onFocus={this.onFocus}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
        {this.renderClearIcon()}
      </div>
    );
  }
}

SearchInput.propTypes = {
  handleKeyPress: PropTypes.func,
  handleSubmit: PropTypes.func,
  searchWord: PropTypes.string,
  shouldFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  clearSearch: PropTypes.func,
  handleBlur: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleFocus: PropTypes.func,
  onProductCategory: PropTypes.bool,
  isMobileNavV3: PropTypes.bool,
  isDesktopNavV3: PropTypes.bool,
  isPostAuthNav3Enabled: PropTypes.bool,
};
