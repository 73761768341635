import fetch from 'isomorphic-fetch';
import { BusinessUnit } from '@zola-helpers/client/dist/es/@types';
import detectBusinessUnit from '@zola-helpers/client/dist/es/util/detectBusinessUnit';

import * as ActionType from './types/SearchActionTypes';

const isHomeStore = typeof window !== 'undefined' && detectBusinessUnit() === BusinessUnit.STORE;

const SEARCH_RESULTS_LIMIT = 10;

function requestProductsSuggestions() {
  return {
    type: ActionType.REQUEST_PRODUCTS_SUGGESTIONS,
  };
}

function receiveProductsSuggestions(response) {
  return {
    type: ActionType.RECEIVE_PRODUCTS_SUGGESTIONS,
    payload: response,
  };
}

function requestCouples() {
  return {
    type: ActionType.REQUEST_COUPLES,
  };
}

function receiveCouples(response) {
  return {
    type: ActionType.RECEIVE_COUPLES,
    payload: response,
  };
}

function requestVendors() {
  return {
    type: ActionType.REQUEST_VENDORS,
  };
}

function receiveVendors(response) {
  return {
    type: ActionType.RECEIVE_VENDORS,
    payload: response,
  };
}

export function setSearchWord(word) {
  return {
    type: ActionType.SET_SEARCH_WORD,
    word,
  };
}

export function getProductsSuggestions(searchWord, limit) {
  const ITEMS_LIMIT = limit || SEARCH_RESULTS_LIMIT;
  return (dispatch, getState) => {
    dispatch(requestProductsSuggestions());
    return fetch('/website-nav/web-api/v1/search/productsSuggestions', {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({
        query: searchWord,
        offset: 0,
        limit: ITEMS_LIMIT,
        enabled_search_categories: isHomeStore ? ['WEB'] : ['WEB', 'INVITES', 'PLANNING_TOOLS'],
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const currentInput = getState().search.word;
        // only update the store with the results from the api if the
        // current search input matches the search word that was used
        // to fetch the response from api
        if (currentInput === searchWord) {
          // temp fix to remove items from search
          const items =
            isHomeStore && json.result.items && json.result.items.length > 0
              ? json.result.items.filter((item) => item.term.indexOf('Crate and Barrel') === -1)
              : json.result.items;
          dispatch(receiveProductsSuggestions(items));
        }
      });
  };
}

export function getCouples(searchWord, limit) {
  const ITEMS_LIMIT = limit || SEARCH_RESULTS_LIMIT;
  return (dispatch, getState) => {
    dispatch(requestCouples());
    const specialChars = /[-!$%^&*()_+|~=`{}[\]:";<>?,./]/;
    if (specialChars.test(searchWord)) {
      return dispatch(receiveCouples([]));
    }
    return fetch('/website-nav/web-api/v1/search/couples', {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({ query: searchWord, offset: 0, limit: ITEMS_LIMIT }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const currentInput = getState().search.word;
        // only update the store with the results from the api if the
        // current search input matches the search word that was used
        // to fetch the response from api
        if (currentInput === searchWord) {
          const results = Array.isArray(json) ? json : [];
          return dispatch(receiveCouples(results));
        }

        return Promise.resolve();
      });
  };
}

export function getVendorSearchResults(searchWord, limit) {
  const ITEMS_LIMIT = limit || SEARCH_RESULTS_LIMIT;
  return (dispatch, getState) => {
    dispatch(requestVendors());
    return fetch(
      `/website-nav/web-api/v1/search/vendors?q=${searchWord}&limit=${ITEMS_LIMIT}&offset=0`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        const currentInput = getState().search.word;
        // only update the store with the results from the api if the
        // current search input matches the search word that was used
        // to fetch the response from api
        if (currentInput === searchWord) {
          const results = Array.isArray(json) ? json : [];
          return dispatch(receiveVendors(results));
        }

        return Promise.resolve();
      });
  };
}

export function resetProductsSuggestions() {
  return (dispatch) => {
    dispatch(receiveProductsSuggestions([]));
  };
}

export function resetCouples() {
  return (dispatch) => {
    dispatch(receiveCouples([]));
  };
}

export function resetVendors() {
  return (dispatch) => dispatch(receiveVendors([]));
}
