import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { BusinessUnit } from '@zola-helpers/client/dist/es/@types';
import detectBusinessUnit from '@zola-helpers/client/dist/es/util/detectBusinessUnit';
import SearchIcon from 'components/navV3/iconsV3/Search';
import CloseIcon from 'components/navV3/iconsV3/CloseIcon';
import featureFlags from 'util/featureFlags';

import { trackProductsSearched } from 'util/trackingHelper';

import {
  CloseSearchButton,
  SearchInputAndCloseContainer,
  SearchIconContainer,
  CloseSearchButtonDesktop,
  ScreenReaderText,
} from 'components/nav/Search/SearchContainer/SearchContainer.styles';
import SearchInput from '../SearchInput';
import SearchResults from '../SearchResults';

import renderIf from '../../../../util/renderIf';
import bindMethods from '../../../../util/bindMethods';

import { searchType, getSearchRedirectRoutes } from '../utils';

import '../Search.less';

export default class SearchContainer extends Component {
  debouncedFetchSearchResults = _debounce(this.fetchSearchResults, 300);

  constructor(props) {
    super(props);

    this.state = {
      searchWord: '',
      category: props.defaultCategory || 'products',
      showResults: false,
      hideSearchInput: false,
    };

    bindMethods(this);
  }

  componentDidMount() {
    const { handleResetCouplesSearch, handleResetProductsSuggestions } = this.props;
    const businessUnit = detectBusinessUnit();
    handleResetCouplesSearch();
    handleResetProductsSuggestions();
    if (businessUnit === BusinessUnit.MARKETPLACE || businessUnit === BusinessUnit.INSPIRATION) {
      this.setState({ category: 'vendors' });
    }

    if (this.isARealWeddingsPage()) {
      this.setState({ hideSearchInput: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleResetCouplesSearch, handleResetProductsSuggestions } = this.props;
    const { searchWord } = this.state;
    if (prevState.searchWord !== '' && searchWord === '') {
      handleResetCouplesSearch();
      handleResetProductsSuggestions();
    }
  }

  getShowAllPath(category) {
    const { searchWord } = this.state;
    switch (category) {
      case 'couples':
        return `/search/wedding-registry?query=${encodeURIComponent(searchWord)}`;
      case 'vendors':
        return `/wedding-vendors/search/wedding-venues`;
      case 'products':
      default:
        return `/search/gifts?q=${encodeURIComponent(searchWord)}`;
    }
  }

  isARealWeddingsPage() {
    const isRealWeddingsLandingPage = new RegExp('^/inspiration(?!/wedding|/photo).*$');
    const isRealWeddingsRegex = new RegExp('^/inspiration(?:/wedding|/photo)?');

    const whichRealWeddingsRegex = featureFlags.get('hideUniversalSearchBarInDetailPages')
      ? isRealWeddingsRegex
      : isRealWeddingsLandingPage;

    return whichRealWeddingsRegex.test(window.location.pathname);
  }

  clearSearch(e) {
    e.preventDefault();
    this.setState({
      searchWord: '',
    });
  }

  handleBlur() {
    const { showResults } = this.state;
    const { toggleSearchActive, isActive, notDesktop } = this.props;
    if (isActive && !notDesktop) toggleSearchActive();
    this.setState({ showResults: !showResults });
  }

  handleFocus() {
    const { showResults } = this.state;
    this.setState({ showResults: !showResults });
  }

  handleKeyDown(e) {
    const { searchWord } = this.state;
    if (searchWord === '') e.target.value = '';
  }

  handleKeyPress(e) {
    const searchWord = e.target.value;
    const { category } = this.state;
    const { handleSetSearchWord } = this.props;

    this.setState(
      {
        searchWord,
      },
      () => {
        handleSetSearchWord(searchWord);
        this.debouncedFetchSearchResults(category);
      }
    );
  }

  // if user presses enter (keycode = 13), then view "show all" page;
  handleSubmit(e) {
    if (e.charCode === 13) {
      const { category, searchWord } = this.state;
      const { userContext, vendorSearchResults } = this.props;

      const noResultsFound = this.results(category).length < 1;
      let eventData = {
        query: searchWord,
        section: 'UNIVERSAL',
        search_type: searchType(category),
        no_results: noResultsFound,
      };

      // check for predefined route for a search word
      const searchRedirectLink = getSearchRedirectRoutes(searchWord, userContext);
      if (searchRedirectLink) {
        trackProductsSearched(eventData);

        if (searchRedirectLink.startsWith('https://')) {
          window.open(searchRedirectLink, '_blank');
        } else {
          window.location.href = searchRedirectLink;
        }

        return;
      }

      let link = this.getShowAllPath(category);

      // if vendor category, go to first result instead of show all page
      if (category === 'vendors' && vendorSearchResults.length) {
        const [firstVendorResult] = vendorSearchResults;
        link = firstVendorResult.url;
        eventData = {
          ...eventData,
          value: firstVendorResult.name,
        };
      }
      trackProductsSearched(eventData);
      window.location.href = link;
    }
  }

  // category can either be `products`, `vendors`, or `couples`;
  handleToggle(category) {
    this.setState(
      {
        category,
      },
      () => {
        this.debouncedFetchSearchResults(category);
      }
    );
  }

  handleSearchInputVisibilityToggle() {
    this.setState({ hideSearchInput: false });
  }

  fetchSearchResults(category) {
    const {
      handleProductsSuggestions,
      handleVendorsSearch,
      handleCouplesSearch,
      handleResetProductsSuggestions,
      handleResetVendorsSearch,
      handleResetCouplesSearch,
    } = this.props;
    const { searchWord } = this.state;

    switch (category) {
      case 'products':
        if (searchWord) {
          handleProductsSuggestions(searchWord);
        } else {
          handleResetProductsSuggestions();
        }
        break;
      case 'vendors':
        if (searchWord) {
          handleVendorsSearch(searchWord);
        } else {
          handleResetVendorsSearch();
        }
        break;
      case 'couples':
        if (searchWord) {
          handleCouplesSearch(searchWord);
        } else {
          handleResetCouplesSearch();
        }
        break;
      default:
    }
  }

  placeholderText() {
    const { isWeddingStoreActive, notDesktop } = this.props;
    const { category } = this.state;

    const vendorPlaceholder = 'Search locations, vendors, vendor types';
    const defaultPlaceholder = 'Search products, vendors, couples';

    // if it is the homestore, only search for products
    if (!isWeddingStoreActive) {
      return 'Search for products';
    }

    if (notDesktop) {
      switch (category) {
        case 'products':
          return 'Search products and brands';
        case 'vendors':
          return vendorPlaceholder;
        case 'couples':
          return 'Search first and last names';
        default:
          return defaultPlaceholder;
      }
    }

    if (category === 'vendors') {
      return vendorPlaceholder;
    }

    return defaultPlaceholder;
  }

  results(category) {
    const { productsSuggestionsResults, coupleSearchResults, vendorSearchResults } = this.props;
    switch (category) {
      case 'couples':
        return coupleSearchResults;
      case 'vendors':
        return vendorSearchResults;
      case 'products':
      default:
        return productsSuggestionsResults;
    }
  }

  render() {
    const { searchWord, category, showResults, hideSearchInput } = this.state;
    const {
      loading,
      isActive,
      notDesktop,
      storeLinks,
      toggleSearchActive,
      hideToggle,
      userContext,
      isDesktopNavV3,
      isPostAuthNav3Enabled,
    } = this.props;
    const onProductCategory = category === 'products';
    const link = this.getShowAllPath(category);

    if (!isActive && notDesktop) return null;

    const isMobileNavV3 = notDesktop && window.location.href.indexOf('homestore') < 0;

    const { is_guest: isGuest } = userContext || {};

    const placeholder =
      this.isARealWeddingsPage() && hideSearchInput ? 'search-input__placeholder-icon' : '';

    return (
      <div
        className={cx(
          'top-nav__search-container-v2',
          placeholder,
          isDesktopNavV3 ? 'search-results__container--desktopNavV3' : ''
        )}
      >
        {this.isARealWeddingsPage() && hideSearchInput ? (
          <SearchIconContainer
            type="button"
            isGuest={Boolean(isGuest)}
            onClick={this.handleSearchInputVisibilityToggle}
          >
            <SearchIcon height={20} width={20} />
          </SearchIconContainer>
        ) : (
          <>
            <SearchInputAndCloseContainer isMobileNavV3={isMobileNavV3}>
              <SearchInput
                onProductCategory={onProductCategory}
                searchWord={searchWord}
                shouldFocus={isActive}
                placeholder={this.placeholderText()}
                handleKeyPress={this.handleKeyPress}
                handleSubmit={this.handleSubmit}
                clearSearch={this.clearSearch}
                handleBlur={this.handleBlur}
                handleKeyDown={this.handleKeyDown}
                handleFocus={this.handleFocus}
                isMobileNavV3={isMobileNavV3}
                userContext={userContext}
                isDesktopNavV3={isDesktopNavV3}
                isPostAuthNav3Enabled={isPostAuthNav3Enabled}
              />
              {renderIf(isMobileNavV3, () => (
                <CloseSearchButton onClick={toggleSearchActive}>Close</CloseSearchButton>
              ))}
              {renderIf(isDesktopNavV3, () => (
                <CloseSearchButtonDesktop onClick={toggleSearchActive}>
                  <CloseIcon width={24} height={24} showTitle={false} />
                  <ScreenReaderText>Close</ScreenReaderText>
                </CloseSearchButtonDesktop>
              ))}
            </SearchInputAndCloseContainer>
            {renderIf((searchWord && showResults) || notDesktop, () => (
              <SearchResults
                loading={loading}
                category={category}
                results={this.results(category)}
                hideToggle={hideToggle}
                handleToggle={this.handleToggle}
                toggleSearchActive={toggleSearchActive}
                searchWord={searchWord}
                cta={link}
                notDesktop={notDesktop}
                noResultsLinks={storeLinks}
                isPostAuthNav3Enabled={isPostAuthNav3Enabled}
              />
            ))}
          </>
        )}
      </div>
    );
  }
}

SearchContainer.propTypes = {
  handleCouplesSearch: PropTypes.func.isRequired,
  handleVendorsSearch: PropTypes.func.isRequired,
  handleProductsSuggestions: PropTypes.func.isRequired,
  handleResetCouplesSearch: PropTypes.func.isRequired,
  handleResetVendorsSearch: PropTypes.func.isRequired,
  handleResetProductsSuggestions: PropTypes.func.isRequired,
  productsSuggestionsResults: PropTypes.arrayOf(PropTypes.shape({})),
  coupleSearchResults: PropTypes.arrayOf(PropTypes.shape({})),
  vendorSearchResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  storeLinks: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  notDesktop: PropTypes.bool,
  isActive: PropTypes.bool,
  hideToggle: PropTypes.bool,
  handleSetSearchWord: PropTypes.func,
  toggleSearchActive: PropTypes.func,
  isWeddingStoreActive: PropTypes.bool, // is it the home store or wedding site
  userContext: PropTypes.shape({}),
  defaultCategory: PropTypes.oneOf(['products, vendors, couples']),
  isDesktopNavV3: PropTypes.bool,
  isPostAuthNav3Enabled: PropTypes.bool,
};
