import type { UserContext } from '@zola-helpers/client/dist/es/@types';

export const getSearchRedirectRoutes = (
  searchWord: string,
  userContext: UserContext
): string | null => {
  const { is_guest } = userContext || {};
  const isGuest = Boolean(is_guest);

  const normalizedSearchWord = searchWord.trim().toLowerCase();

  switch (normalizedSearchWord) {
    case 'add registry':
      return isGuest ? '/wedding-registry' : `/registry/${userContext.slug}/edit`;
    case 'address labels':
    case 'address':
    case 'guest addressing':
      return isGuest
        ? '/faq/360009615491-how-do-i-use-zola-to-address-my-invitations-'
        : '/wedding/manage/guests/envelopes';
    case 'addresses':
      return isGuest ? '/wedding-planning' : '/wedding/manage/guests/all';
    case 'announcements':
    case 'diy portrait':
    case 'postcard':
    case 'upload your own design':
      return '/wedding-planning/save-the-date/shop';
    case 'article':
      return '/expert-advice';
    case 'cash fund':
    case 'cash funds':
    case 'honeyfund':
    case 'honeymoon fund':
    case 'house fund':
      return '/shop/honeymoon-cash-funds';
    case 'check list':
    case 'checklist':
    case 'wedding checklist':
      return '/wedding-planning/checklist';
    case 'day of coordinator':
      return '/wedding-vendors/wedding-planners';
    case 'delete account':
      return '/faq/115003093871-we-no-longer-need-our-zola-account-can-we-deactivate-it-';
    case 'delete website':
      return isGuest
        ? '/faq/115001755171-how-do-i-delete-my-website-registry-'
        : '/wedding/manage/website/manage';
    case 'digital invitation':
    case 'digital invitations':
    case 'digital invite':
    case 'digital invites':
    case 'digital rsvp':
    case 'evites':
      return '/wedding-planning/digital/save-the-date/shop';
    case 'elope':
    case 'elopement':
    case 'we eloped':
      return '/expert-advice/what-to-write-on-an-elopement-invitation';
    case 'email':
    case 'email guests':
      return isGuest
        ? '/faq/115002148872-can-i-send-a-message-to-my-guest-or-a-bulk-message-from-my-guest-list-'
        : '/wedding/manage/guests/collection-link';
    case 'enclosure card':
    case 'itinerary':
    case 'rehearsal dinner invitation':
    case 'rehearsal dinner invitations':
    case 'rehearsal dinner invite':
    case 'rehearsal dinner':
    case 'rehearsal':
      return '/wedding-planning/enclosures/shop';
    case 'escort cards':
      return '/wedding-planning/place-cards/shop';
    case 'florist':
      return '/wedding-vendors/wedding-florists';
    case 'gift tracker':
      return isGuest ? '/registry/gift-tracker/get-started' : '/registry/gift-tracker/your-gifts';
    case 'guest messaging':
      return isGuest
        ? '/faq/115002148872-can-i-send-a-message-to-my-guest-or-a-bulk-message-from-my-guest-list-'
        : '/wedding/manage/guests/all';
    case 'guest list':
      return isGuest ? '/wedding-planning/guests' : '/wedding/manage/guests/all';
    case 'hashtag generator':
      return '/expert-advice/9-easy-ways-to-create-your-wedding-hashtag';
    case 'hotel blocks':
      return 'https://hotelblocks.zola.com/?kw=guestnav';
    case 'menu cards':
      return '/wedding-planning/menus/shop';
    case 'message guests':
      return isGuest
        ? '/faq/115002148872-can-i-send-a-message-to-my-guest-or-a-bulk-message-from-my-guest-list-'
        : '/wedding/manage/guests/envelopes';
    case 'officiant':
    case 'vendors':
      return '/wedding-vendors';
    case 'online rsvp':
    case 'rsvp':
      return isGuest ? '/search/wedding-registry' : '/wedding/manage/guests/rsvps/overview';
    case 'photographer':
    case 'photographers':
      return '/wedding-vendors/wedding-photographers';
    case 'postcards':
      return '/wedding-planning/save-the-date/shop?orientations=landscape';
    case 'price match':
      return '/faq/115002838511-does-zola-offer-price-matching-what-is-zola-s-price-matching-policy-';
    case 'promo code':
      return isGuest
        ? '/faq/115002837972-do-we-get-any-bonus-gifts-or-perks-for-registering-at-zola-'
        : '/wedding-registry/perks';
    case 'qr code':
    case 'qr codes':
    case 'qr':
    case 'wedding invitation':
    case 'wedding invitations':
    case 'wedding invites':
      return '/wedding-planning/invitations/shop';
    case 'return':
    case 'returns':
      return isGuest ? '/order-status/lookup' : '/account/settings/orders';
    case 'rsvp card':
    case 'rsvp cards':
    case 'rsvp qr code':
      return '/wedding-planning/invitations/shop?offset=0';
    case 'rsvps':
      return isGuest
        ? '/wedding-planning/invitations/shop?offset=0'
        : '/wedding/manage/guests/rsvps/overview';
    case 'seating chart':
    case 'seating charts':
      return isGuest ? '/wedding-planning' : '/wedding/manage/seating';
    case 'to do list':
      return isGuest
        ? '/expert-advice/checklist/your-ultimate-wedding-planning-checklist'
        : '/wedding/manage/wedding-checklist';
    case 'vow renewal':
      return '/expert-advice/vow-renewal-checklist';
    case 'website':
    case 'websites':
      return isGuest ? '/wedding-planning/website' : '/wedding/manage/website/manage';
    case 'wedding hashtag':
      return isGuest
        ? '/expert-advice/9-easy-ways-to-create-your-wedding-hashtag'
        : '/account/settings/wedding';
    case 'wedding program':
    case 'wedding programs':
      return '/wedding-planning/programs/shop';
    case 'wedding timeline':
      return '/expert-advice/checklist/your-ultimate-wedding-planning-checklist';
    default:
      return null;
  }
};
