import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@zola/zola-ui/src/components/Link';

import { trackProductsSearched } from 'util/trackingHelper';
import renderIf from 'util/renderIf';

import SearchResultsProduct from '../SearchResultsProduct';
import SearchResultsCouple from '../SearchResultsCouple';
import SearchResultsVendor from '../SearchResultsVendor';
import SearchCategoryChips from './SearchCategoryChips';

import { searchType } from '../utils/utils';

export default class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.renderShowAll = this.renderShowAll.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { category, results, searchWord } = this.props;
    const noResultsFound = searchWord && results && results.length === 0;
    if (
      noResultsFound &&
      category !== 'products' &&
      (prevProps.category !== category || prevProps.results !== results)
    ) {
      trackProductsSearched({
        query: searchWord,
        section: 'UNIVERSAL',
        search_type: searchType(category),
        no_results: true,
      });
    }
  }

  handleTouch() {
    const { notDesktop } = this.props;
    if (notDesktop) {
      // on mobile, this forces the keyboard to close, which makes scrolling through results better
      const searchInput = document.getElementById('zola-search');
      if (searchInput) searchInput.blur();
    }
  }

  categoryResult(category, result, searchWord, index) {
    const { isPostAuthNav3Enabled } = this.props;

    switch (category) {
      case 'couples':
        return (
          <SearchResultsCouple
            searchWord={searchWord}
            couple={result}
            resultListPositionNumber={index + 1}
          />
        );
      case 'vendors':
        return (
          <SearchResultsVendor
            result={result}
            searchWord={searchWord}
            resultListPositionNumber={index + 1}
            isPostAuthNav3Enabled={isPostAuthNav3Enabled}
          />
        );
      case 'products':
      default:
        return (
          <SearchResultsProduct
            searchWord={searchWord}
            product={result}
            resultListPositionNumber={index + 1}
          />
        );
    }
  }

  renderShowAll() {
    const { category, cta, searchWord, notDesktop } = this.props;

    // remove the 's' from 'products' & 'couples' (vendors doesn't show this link)
    const singularCategory = category.slice(0, -1);

    return (
      <Link href={cta} className="search-results__show-all-link" arrow>
        {notDesktop ? 'See' : 'Show'} all {singularCategory} results for {`"${searchWord}"`}
      </Link>
    );
  }

  render() {
    const {
      category,
      results = [],
      handleToggle,
      loading,
      searchWord,
      notDesktop,
      noResultsLinks = [],
      toggleSearchActive,
      hideToggle,
      isPostAuthNav3Enabled,
    } = this.props;
    const productCategoryIsActive = category === 'products';
    const vendorCategoryIsActive = category === 'vendors';
    const totalResults = results.length;
    const noResults = totalResults < 1;
    const isHomeStoreActive = window.location.href.indexOf('homestore') >= 0;
    const showAllLink =
      (!noResults || (noResults && productCategoryIsActive)) &&
      searchWord &&
      !notDesktop &&
      !vendorCategoryIsActive; // marketplace doesn't have a general search results page
    const showInitialProductSuggestions =
      noResults &&
      productCategoryIsActive &&
      !isHomeStoreActive &&
      !searchWord &&
      notDesktop &&
      noResultsLinks.length > 0;

    return (
      <div
        className={classNames('search-results__container', {
          'search-results__container--postAuthNav3': isPostAuthNav3Enabled,
        })}
        onMouseDown={(e) => e.preventDefault()}
        onTouchStart={this.handleTouch}
      >
        {hideToggle ? null : SearchCategoryChips({ category, handleToggle, searchWord })}
        {showInitialProductSuggestions && (
          <div className="search-results__categories">
            {noResultsLinks.map((link) => (
              <a
                onClick={toggleSearchActive}
                href={link.path}
                key={link.title}
                className="search-results__category"
              >
                {link.title}
              </a>
            ))}
          </div>
        )}
        {!showInitialProductSuggestions && (
          <div
            className={classNames('search-results__results-container', {
              'search-results__results-container--active': !noResults,
            })}
          >
            {renderIf(
              (!noResults || (noResults && productCategoryIsActive)) &&
                searchWord &&
                notDesktop &&
                !vendorCategoryIsActive, // marketplace doesn't have a general search results page
              this.renderShowAll
            )}
            {results.map((result, index) => (
              <div
                key={`result_${index}`}
                className={classNames('search-results__result', {
                  vendors: vendorCategoryIsActive,
                })}
              >
                {this.categoryResult(category, result, searchWord, index)}
              </div>
            ))}
          </div>
        )}

        {renderIf(showAllLink, this.renderShowAll)}

        {renderIf(noResults && !loading && !productCategoryIsActive && searchWord, () => (
          <Fragment>
            {category === 'couples' && (
              <div className="search-results__no-results">
                No couples found matching{' '}
                <span className="search-results__search-word">&quot;{searchWord}&quot;</span>. Try
                again.
              </div>
            )}
            {category === 'vendors' && (
              <div className="search-results__no-results">
                No vendors found matching{' '}
                <span className="search-results__search-word">&quot;{searchWord}&quot;</span>. Try
                again.
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

SearchResults.propTypes = {
  category: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.shape({})),
  handleToggle: PropTypes.func,
  toggleSearchActive: PropTypes.func,
  loading: PropTypes.bool,
  searchWord: PropTypes.string,
  cta: PropTypes.string,
  notDesktop: PropTypes.bool,
  hideToggle: PropTypes.bool,
  isPostAuthNav3Enabled: PropTypes.bool,
  noResultsLinks: PropTypes.arrayOf(PropTypes.shape({})),
};
