import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackSearchSuggestionClicked } from 'util/trackingHelper';
import SearchResultMatch from './SearchResultMatch';

class SearchResultsCouple extends Component {
  getInitials(name) {
    const firstAndLastName = name.split(' ');
    const initials = `${firstAndLastName[0].charAt(0)}${firstAndLastName[1].charAt(0)}`;
    return initials;
  }

  getInitialsOfCouple() {
    const { couple } = this.props;
    const { owner_name: ownerName, partner_name: partnerName } = couple;
    const ownerInitials = this.getInitials(ownerName);
    const partnerInitials = this.getInitials(partnerName);
    return `${ownerInitials.split('')[0]}+${partnerInitials.split('')[0]}`;
  }

  getPath() {
    const { couple } = this.props;
    const { slug } = couple;

    return couple.public_wedding_account_id ? `/wedding/${slug}` : `/registry/${slug}`;
  }

  getEventDate(date) {
    return new Date(date).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    });
  }

  hasIcon() {
    const { couple } = this.props;
    const fakeIconPaths = ['couple-mf', 'couple-ff', 'couple-mm'];
    const includesFakePath = fakeIconPaths.some(
      (path) => couple.image_url && couple.image_url.indexOf(path) > -1
    );

    if (!couple.image_url || includesFakePath) {
      return false;
    }

    return true;
  }

  render() {
    const { couple, searchWord, resultListPositionNumber } = this.props;
    const coupleName = `${couple.owner_name} & ${couple.partner_name}`;
    const trackingParams = {
      query: searchWord,
      section: 'UNIVERSAL',
      search_type: 'COUPLE',
      position: resultListPositionNumber,
      value: coupleName,
    };

    return (
      <a
        href={this.getPath()}
        target="_self"
        onClick={() => trackSearchSuggestionClicked(trackingParams)}
      >
        <div className="search-results__couple">
          <div className="search-results__icon">
            {this.hasIcon(couple) ? (
              <div
                className="search-results__image"
                style={{ backgroundImage: `url(${couple.image_url})` }}
              />
            ) : (
              <span className="search-results__initials">{this.getInitialsOfCouple()}</span>
            )}
          </div>
          <div className="search-results__details">
            <div className="search-results__name">
              <SearchResultMatch searchWord={searchWord} result={coupleName} />
            </div>
            {couple.event_date && (
              <div className="search-results__date">{this.getEventDate(couple.event_date)}</div>
            )}
          </div>
        </div>
      </a>
    );
  }
}

SearchResultsCouple.propTypes = {
  couple: PropTypes.shape({
    owner_name: PropTypes.string,
    partner_name: PropTypes.string,
    image_url: PropTypes.string,
    event_date: PropTypes.string,
    slug: PropTypes.string,
    public_wedding_account_id: PropTypes.number,
  }),
  searchWord: PropTypes.string,
  resultListPositionNumber: PropTypes.number,
};

export default SearchResultsCouple;
