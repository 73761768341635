import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchResultMatch extends Component {
  getMatches() {
    const { searchWord, result } = this.props;
    // only match letters
    const parsedSearchWord =
      searchWord && searchWord.match(/[a-zA-Z0-9]+/gi) && searchWord.match(/[a-zA-Z0-9]+/gi)[0];
    const searchRegex = RegExp(`${parsedSearchWord}`, 'gi');
    let regexResult;
    const matches = [];

    // eslint-disable-next-line no-cond-assign
    while (searchWord && (regexResult = searchRegex.exec(result)) !== null) {
      const startIndex = regexResult.index;
      matches.push(startIndex);
    }

    return matches;
  }

  getSearchResultMatch() {
    const { result: searchResult, searchWord } = this.props;
    const matches = this.getMatches();
    const matchLength =
      searchWord &&
      searchWord.match(/[a-zA-Z0-9]+/gi) &&
      searchWord.match(/[a-zA-Z0-9]+/gi)[0].length;
    const elements = [];
    let startIndex = 0;
    let currentMatchIndex = matches.shift();

    while (startIndex < searchResult.length) {
      if (currentMatchIndex !== 'undefined' && currentMatchIndex === startIndex) {
        const endIndex = startIndex + matchLength;
        const matchingWord = searchResult.slice(startIndex, endIndex);
        startIndex = endIndex;
        currentMatchIndex = matches.shift();
        elements.push(this.getHighlightedSpan(matchingWord, startIndex));
      } else {
        const endIndex = currentMatchIndex || searchResult.length;
        const nonMatchingWord = searchResult.slice(startIndex, endIndex);
        startIndex = endIndex;
        elements.push(this.getSpan(nonMatchingWord, startIndex));
      }
    }

    return elements;
  }

  getSpan(word, index) {
    return <span key={`${word}-${index}`}>{word}</span>;
  }

  getHighlightedSpan(word, index) {
    return (
      <span key={`${word}-${index}`} className="search-result__matched">
        {word}
      </span>
    );
  }

  render() {
    return <div className="search-result-match">{this.getSearchResultMatch()}</div>;
  }
}

SearchResultMatch.propTypes = {
  result: PropTypes.string,
  searchWord: PropTypes.string,
};

export default SearchResultMatch;
