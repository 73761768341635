import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackSearchSuggestionClicked } from 'util/trackingHelper';
import SearchResultMatch from './SearchResultMatch';

class SearchResultsProduct extends Component {
  getPath() {
    const { product } = this.props;
    if (product.brand_view) {
      return `/shop/brand/${product.brand_view.key}`;
    }
    if (product.custom_result_view) {
      return `${product.custom_result_view.destination_url}`;
    }
    return `/search/gifts?q=${encodeURIComponent(product.term)}`;
  }

  render() {
    const { searchWord, product, resultListPositionNumber } = this.props;
    const trackingParams = {
      query: searchWord,
      section: 'UNIVERSAL',
      search_type: 'PRODUCT',
      position: resultListPositionNumber,
      value: product && product.term,
    };
    return (
      <a
        href={this.getPath()}
        target="_self"
        onClick={() => trackSearchSuggestionClicked(trackingParams)}
        className="search-results__product-link"
      >
        <div className="search-results__product">
          {product && <SearchResultMatch searchWord={searchWord} result={product.term} />}
          {product.brand_view && <span className="search-results__brand"> in brands</span>}
          {product.custom_result_view && (
            <span className="search-results__brand"> {product.custom_result_view.append_text}</span>
          )}
        </div>
      </a>
    );
  }
}

SearchResultsProduct.propTypes = {
  product: PropTypes.shape({
    brand_view: PropTypes.shape({
      key: PropTypes.string,
    }),
    custom_result_view: PropTypes.shape({
      destination_url: PropTypes.string,
      append_text: PropTypes.string,
    }),
    term: PropTypes.string,
  }),
  searchWord: PropTypes.string,
  resultListPositionNumber: PropTypes.number,
};

export default SearchResultsProduct;
