// eslint-disable-next-line import/prefer-default-export
export const searchType = (category) => {
  switch (category) {
    case 'couples':
      return 'COUPLE';
    case 'vendors':
      return 'VENDOR';
    case 'products':
      return 'PRODUCT';
    default:
      return 'NULL';
  }
};
