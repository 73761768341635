import React from 'react';
import PropTypes from 'prop-types';

import getEnvironment from '@zola-helpers/client/dist/es/util/environment';

import GeoPinIcon from '@zola/zola-ui/src/components/SvgIcons/GeoPin';
import AccountIcon from '@zola/zola-ui/src/components/SvgIcons/Account';
import AgnosticVenue from '@zola/zola-ui/src/assets/images/icons/agnostic_venue.svg';
import Camera from '@zola/zola-ui/src/assets/images/icons/camera.svg';
import Video from '@zola/zola-ui/src/assets/images/icons/video.svg';
import Bouquet from '@zola/zola-ui/src/assets/images/icons/bouquet.svg';
import Mixer from '@zola/zola-ui/src/assets/images/icons/mixer.svg';
import HairMakeup from '@zola/zola-ui/src/assets/images/icons/hair_makeup.svg';
import BandsDj from '@zola/zola-ui/src/assets/images/icons/bands_dj.svg';
import WeddingCake from '@zola/zola-ui/src/assets/images/icons/wedding_cake.svg';
import LoveBirds from '@zola/zola-ui/src/assets/images/icons/love_birds.svg';

import { MapPinIcon } from '@zola/zola-ui/src/components/SvgIconsV3/MapPin';
import {
  AgnosticVenue as RebrandAgnosticVenue,
  Camera as RebrandCamera,
  Video as RebrandVideo,
  Bouquet as RebrandBouquet,
  Mixer as RebrandMixer,
  HairMakeup as RebrandHairMakeup,
  Band as RebrandBand,
  WeddingCake as RebrandWeddingCake,
  LoveBirds as RebrandLoveBirds,
} from '@zola/zola-ui/src/assets/images/iconsRebrand';

import { trackSearchSuggestionClicked } from 'util/trackingHelper';

import SearchResultMatch from './SearchResultMatch';

const RESULT_TYPES = [
  'STOREFRONT',
  'US_CITY',
  'VENDOR_CATEGORY',
  'VENDOR_CITY_CATEGORY',
  'CUSTOM_RESULT',
];

const ICON_LOOKUP = {
  VENUE: AgnosticVenue,
  PHOTOGRAPHER: Camera,
  VIDEOGRAPHER: Video,
  FLORIST: Bouquet,
  CATERER: Mixer,
  HAIR_MAKEUP: HairMakeup,
  MUSICIAN_DJ: BandsDj,
  CAKES_DESSERTS: WeddingCake,
};

const REBRAND_ICON_LOOKUP = {
  VENUE: RebrandAgnosticVenue,
  PHOTOGRAPHER: RebrandCamera,
  VIDEOGRAPHER: RebrandVideo,
  FLORIST: RebrandBouquet,
  CATERER: RebrandMixer,
  HAIR_MAKEUP: RebrandHairMakeup,
  MUSICIAN_DJ: RebrandBand,
  CAKES_DESSERTS: RebrandWeddingCake,
};

const PROD_BASE_URL = 'https://images.zola.com';
const STAGE_BASE_URL = '//stage-images.zola.com';
const DEV_BASE_URL = '//127.0.0.1:9100/v2/image';

const getBaseImgUrl = () => {
  switch (getEnvironment()) {
    case 'production':
      return PROD_BASE_URL;
    case 'staging':
      return STAGE_BASE_URL;
    default:
      return process.env.REACT_APP_ENV === 'devstaging' ? STAGE_BASE_URL : DEV_BASE_URL;
  }
};

const displayIcon = (resultType, vendorType, thumbnail, isPostAuthNav3Enabled) => {
  switch (resultType) {
    case 'STOREFRONT':
      if (thumbnail) {
        return (
          <img
            src={`${getBaseImgUrl()}/${thumbnail}?w=64`}
            className="search-icon storefront-icon"
            alt=""
          />
        );
      }
      return <AccountIcon className="search-icon storefront-default-icon" width={28} height={28} />;
    case 'US_CITY':
      return isPostAuthNav3Enabled ? (
        <MapPinIcon
          title=""
          className="search-icon location-icon location-icon-rebrand"
          width={28}
          height={28}
        />
      ) : (
        <GeoPinIcon className="search-icon location-icon" width={28} height={28} />
      );
    case 'VENDOR_CATEGORY':
    case 'VENDOR_CITY_CATEGORY':
    default:
      return isPostAuthNav3Enabled ? (
        <img
          src={REBRAND_ICON_LOOKUP[vendorType] || RebrandLoveBirds}
          className="search-icon vendor-category-icon-rebrand"
          alt=""
        />
      ) : (
        <img
          src={ICON_LOOKUP[vendorType] || LoveBirds}
          className="search-icon vendor-category-icon"
          alt=""
        />
      );
  }
};

const SearchResultsVendor = ({
  searchWord,
  result,
  resultListPositionNumber,
  isPostAuthNav3Enabled,
}) => {
  const { appendText, name, resultType, thumbnail, url, vendorType } = result;
  const isCustomResult = resultType === 'CUSTOM_RESULT';

  const trackingParams = {
    query: searchWord,
    section: 'UNIVERSAL',
    search_type: 'VENDOR',
    position: resultListPositionNumber,
    value: name,
  };

  return (
    <a
      href={url || '#'}
      target="_self"
      className={isCustomResult ? 'custom-result' : ''}
      onClick={() => {
        trackSearchSuggestionClicked(trackingParams);
        const splitUrl = result.url.split('/');
        const searchKey = splitUrl[splitUrl.length - 1];
        const CITY_SLUG_KEY = 'zola_marketplace_selected_city_from_nav';
        const MARKETPLACE_RECORDED_CITY_KEY = 'zola_marketplace_selected_city';

        // web-marketplace needs to know the city being clicked to properly populate
        // the city being searched for on each individual listing page (overview section and breadcrumb)
        // this is because in the breadcrumb, we want to display the city that was searched for, not the home city
        // of the vendor (that way you can go "back" to your previous search easily by clicking on the breadcrumb)
        // see comment and ticket here:
        // https://newamsterdamlabs.atlassian.net/browse/INSPO-4379?focusedCommentId=130319
        if (resultType === 'US_CITY') {
          // searchKey === e.g. 'irving-tx'
          localStorage.setItem(CITY_SLUG_KEY, searchKey);
        } else if (resultType === 'VENDOR_CITY_CATEGORY') {
          // searchKey === e.g. 'irving-tx--wedding-florists'
          const cityKey = searchKey.split('--')[0];
          localStorage.setItem(CITY_SLUG_KEY, cityKey);
        } else if (resultType === 'STOREFRONT') {
          // if a specific storefront is being used, we want to clear all info about
          // which city was being previously searched for, so the city that shows
          // in the breadcrumb is the same city that the storefront is in
          localStorage.removeItem(MARKETPLACE_RECORDED_CITY_KEY);
        }
      }}
    >
      {!isCustomResult && displayIcon(resultType, vendorType, thumbnail, isPostAuthNav3Enabled)}
      <SearchResultMatch searchWord={searchWord} result={name} />
      {isCustomResult && <span className="search-results__brand">{appendText}</span>}
    </a>
  );
};

SearchResultsVendor.propTypes = {
  searchWord: PropTypes.string,
  result: PropTypes.shape({
    appendText: PropTypes.string,
    name: PropTypes.string,
    resultType: PropTypes.oneOf(RESULT_TYPES),
    thumbnail: PropTypes.string,
    url: PropTypes.string,
    vendorType: PropTypes.string,
  }),
  resultListPositionNumber: PropTypes.number,
  isPostAuthNav3Enabled: PropTypes.bool,
};

export default SearchResultsVendor;
