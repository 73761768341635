import styled from '@emotion/styled';

import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const CloseSearchButton = styled.button`
  background-color: transparent;
  color: ${COLORS.BLACK};
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  ${FONT.textSizeRegular(FONT.FONT_SIZE_SMALLER)}
  border: none;
  margin-left: ${SPACING.SM};
`;

type SearchInputAndCloseContainerProps = {
  isMobileNavV3: boolean;
};
export const SearchInputAndCloseContainer = styled('div')<SearchInputAndCloseContainerProps>`
  display: flex;
  padding: ${({ isMobileNavV3 }) => isMobileNavV3 && SPACING.MD};
  background: ${COLORS.PRIVILEGE};
`;

export const SearchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45%;
  padding: 10px;
  margin-right: ${(props: { isGuest: boolean }) => (props.isGuest ? '-8px' : '-20px')};

  &:hover {
    background: ${COLORS3.BLACK_005};
    cursor: pointer;
  }
`;

export const CloseSearchButtonDesktop = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: ${COLORS3.WHITE_100};
  color: ${COLORS3.BLACK_100};
  padding-left: 20px;
  &:hover {
    color: ${COLORS3.BLACK_075};
    cursor: pointer;
  }
  &:focus {
    outline: highlight auto 2px;
    outline: -webkit-focus-ring-color auto 2px;
  }
`;

export const ScreenReaderText = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
